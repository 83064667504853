import React from 'react'
import { Oval } from 'react-loader-spinner'

const Loader = () => {
  return (

    <Oval
      visible={true}
      height="30"
      width="30"
      color='#DA1F24'
      secondaryColor='#DA1F24'
      ariaLabel="oval-loading"
      wrapperStyle={{}}
      wrapperClass=""
    />
  )

}

export default Loader
