import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import {
  createDriveFromResponse,
  resetCreateDriveError,
  resetCreateDriveSuccess,
} from "../../redux/features/driveFromJobResSlice";
import { handleSnackbar } from "../../redux/features/snackbarSlice";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../ui/dialog";
import { Button } from "../ui/button";
import { Label } from "../ui/label";
import { Input } from "../ui/input";
import { Textarea } from "../ui/textarea";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../ui/accordion";
import { Badge } from "../ui/badge";
import { toast } from "sonner";
import { CalendarIcon, Loader } from "lucide-react";
import { Calendar } from "../ui/calendar";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import { format } from "date-fns";
import { cn } from "../../lib/utils";

const roundStatusEnum = {
  ONGOING: "Ongoing",
  SCHEDULED: "Scheduled",
  COMPLETED: "Completed",
};

const applicantStatusEnum = {
  APPLIED: "Applied",
  ACCEPTED: "Accepted",
  REJECTED: "Rejected",
  SHORTLISTED: "Shortlisted",
  WITHDRAWN: "Withdrawn",
};
const CreateDriveModal = ({ children, modalOpen, setModalOpen }) => {
  const { job:jobData } = useSelector((state) => state.jobFormDetailReducer);
  const job = jobData?.[0]?.job_form
  const { loading, message, success, error } = useSelector(
    (state) => state.driveFromJobResReducer
  );
  const { user } = useSelector((state) => state.loginReducer);
  const [open, setOpen] = useState(false);
  const [roundsDialogOpen, setRoundsDialogOpen] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [createDriveFields, setCreateDriveFields] = useState({
    driveName: "",
    companyName: job?.name_of_company,
    address: job?.address,
    jobProfile: job?.job_profile,
    contactName: job?.contact_name,
    contactEmail: job?.contact_email,
    contactNumber: job?.contact_phone_number,
    prefferedBranches: job?.target_pool.map((pool) => pool.branch),
    startDate: "",
    endDate: "",
    ctcOffered: job?.ctc_offered,
    graduatingYear: "",
    description: job?.job_description,
    eligibilityCriteria: job?.eligibility_criteria,
    jobFormId: job?.id
  });

  const [roundFields, setRoundFields] = useState([
    {
      name: "Resume Shortlisting",
      statuses: [
        roundStatusEnum.ONGOING,
        roundStatusEnum.SCHEDULED,
        roundStatusEnum.COMPLETED,
      ],
      applicantStatuses: [
        applicantStatusEnum.APPLIED,
        applicantStatusEnum.ACCEPTED,
        applicantStatusEnum.REJECTED,
        applicantStatusEnum.WITHDRAWN,
        applicantStatusEnum.SHORTLISTED,
      ],
    },
  ]);
  const [roundName, setRoundName] = useState("");
  const [roundStatus, setRoundStatus] = useState("");
  const [roundApplicantStatus, setRoundApplicantStatus] = useState("");
  const [statuses, setStatuses] = useState([
    roundStatusEnum.ONGOING,
    roundStatusEnum.SCHEDULED,
    roundStatusEnum.COMPLETED,
  ]);
  const [applicantStatuses, setApplicantStatuses] = useState([
    applicantStatusEnum.APPLIED,
    applicantStatusEnum.ACCEPTED,
    applicantStatusEnum.REJECTED,
    applicantStatusEnum.WITHDRAWN,
    applicantStatusEnum.SHORTLISTED,
  ]);
  const handleChange = (e) => {
    setCreateDriveFields({
      ...createDriveFields,
      [e.target.name]: e.target.value,
    });
  };

  const handleAddRoundStatus = () => {
    setStatuses([...statuses, roundStatus]);
    setRoundStatus("");
  };
  const handleAddRoundApplicantStatus = () => {
    setApplicantStatuses([...applicantStatuses, roundApplicantStatus]);
    setRoundApplicantStatus("");
  };

  const handleDeleteStatus = (index) => {
    const newStatuses = [...statuses];
    newStatuses.splice(index, 1);
    setStatuses(newStatuses);
  };

  const handleDeleteApplicantStatus = (index) => {
    const newApplicantStatuses = [...applicantStatuses];
    newApplicantStatuses.splice(index, 1);
    setApplicantStatuses(newApplicantStatuses);
  };

  const handleClose = () => {
    setRoundName("");
    setStatuses([
      roundStatusEnum.ONGOING,
      roundStatusEnum.SCHEDULED,
      roundStatusEnum.COMPLETED,
    ]);
    setApplicantStatuses([
      applicantStatusEnum.APPLIED,
      applicantStatusEnum.ACCEPTED,
      applicantStatusEnum.REJECTED,
      applicantStatusEnum.WITHDRAWN,
      applicantStatusEnum.SHORTLISTED,
    ]);
  };
  const handleAddRound = () => {
    if (roundName === "") {
      return toast.warning("Please enter a round name");
    }
    const newRound = {
      name: roundName,
      statuses: statuses,
      applicantStatuses: applicantStatuses,
    };

    toast.success(`${roundName} added successfully`);
    setRoundFields([...roundFields, newRound]);

    handleClose();
  };

  const handleStartDateChange = (value) => {
    const year = value.$y;
    const month = value.$M + 1; // Months are zero-indexed in JavaScript, so add 1
    const day = value.$D;

    const dateString = `${year}-${month.toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")}`;
    setCreateDriveFields({ ...createDriveFields, startDate: dateString });
  };
  const handleEndDateChange = (value) => {
    const year = value.$y;
    const month = value.$M + 1; // Months are zero-indexed in JavaScript, so add 1
    const day = value.$D;

    const dateString = `${year}-${month.toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")}`;
    setCreateDriveFields({ ...createDriveFields, endDate: dateString });
  };

  // Utility function to add one day to a date
  const addOneDay = (date) => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + 1); // Add one day
    return newDate.toISOString().split("T")[0]; // Return YYYY-MM-DD format
  };

  // Utility function to subtract one day from a date
  const subtractOneDay = (date) => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() - 1); // Subtract one day
    return newDate.toISOString().split("T")[0]; // Return YYYY-MM-DD format
  };

  //Get today's date
  const today = new Date().toISOString().split("T")[0];

  const dispatch = useDispatch();
  const handleCreateDrive = () => {
    
  
    createDriveFields["rounds"] = roundFields;
    createDriveFields["startDate"] = startDate;
    createDriveFields["endDate"] = endDate;
    createDriveFields["jobFormId"] = createDriveFields.jobFormId;
    
    // Validate startDate and endDate
    if (!startDate || !endDate) {
      return toast.warning("Please select valid start and end dates");
    }

    if (
      createDriveFields.graduatingYear === "" ||
      createDriveFields.graduatingYear.length != 4 ||
      Number(createDriveFields.graduatingYear) > new Date().getFullYear() + 2
    ) {
      return toast.warning("Enter a valid year");
    }
    if (
      createDriveFields.driveName === "" ||
      createDriveFields.description === "" ||
      createDriveFields.eligibilityCriteria === "" ||
      createDriveFields.startDate === "" ||
      createDriveFields.endDate === "" ||
      createDriveFields.graduatingYear === ""
    ) {
      return toast.warning("Please enter all the fields");
    }
    dispatch(createDriveFromResponse({ body: createDriveFields }));
  };

  useEffect(() => {
    if (success) {
      toast.success("Drive Created Successfully");
      dispatch(resetCreateDriveSuccess());
      setOpen(false);
    }
    if (error) {
      toast.error("Something went wrong");
      dispatch(resetCreateDriveError());
    }
  }, [success, error]);


  useEffect(() => {
    if (job && job.selection_procedure) {
      const updatedRounds = job.selection_procedure.map((round) => ({
        name: round,
        statuses: [
          roundStatusEnum.ONGOING,
          roundStatusEnum.SCHEDULED,
          roundStatusEnum.COMPLETED,
        ],
        applicantStatuses: [
          applicantStatusEnum.APPLIED,
          applicantStatusEnum.ACCEPTED,
          applicantStatusEnum.REJECTED,
          applicantStatusEnum.WITHDRAWN,
          applicantStatusEnum.SHORTLISTED,
        ],
      }));
      
      // Update roundFields state with the selection procedure data
      setRoundFields(updatedRounds);
    }
  }, [job]); // Only re-run if job changes
  
  return (
    <div>
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogTrigger>
          <Button variant="primary" size="md" className="shadow-md text-base">
            Create Drive
          </Button>
        </DialogTrigger>
        <DialogContent
          className={"lg:max-w-screen-md overflow-y-scroll max-h-[70vh]"}
        >
          <DialogHeader>
            <DialogTitle className="text-xl">Create Drive</DialogTitle>
            <DialogDescription className="mt-3 flex flex-col space-y-3">
              <div className="flex flex-col gap-y-4">
                <div className="flex flex-col gap-y-2">
                  <Label
                    className="text-black font-semibold"
                    htmlFor="driveName"
                  >
                    Drive Name <span className="text-[#DC2327] text-center">*</span>
                  </Label>
                  <Input
                    onChange={handleChange}
                    name="driveName"
                    defaultValue={createDriveFields?.driveName}
                    placeholder="Enter Drive Name"
                  />
                </div>

                <div className="flex items-center gap-x-4 w-full">
                  <div className="flex flex-col gap-y-2 w-1/2">
                    <Label
                      className="text-black font-semibold"
                      htmlFor="companyName"
                    >
                      Company Name
                    </Label>
                    <Input
                      disabled
                      name="companyName"
                      placeholder="Company Name"
                      defaultValue={createDriveFields?.companyName}
                    />
                  </div>
                  <div className="flex flex-col gap-y-2 w-1/2">
                    <Label
                      className="text-black font-semibold"
                      htmlFor="address"
                    >
                      Address
                    </Label>
                    <Input
                      disabled
                      name="address"
                      placeholder="Company Address"
                      defaultValue={createDriveFields?.address}
                    />
                  </div>
                </div>
                <div className="flex items-center gap-x-4 w-full">
                  <div className="flex flex-col gap-y-2 w-1/2">
                    <Label
                      className="text-black font-semibold"
                      htmlFor="jobProfile"
                    >
                      Job Profile
                    </Label>
                    <Input
                      disabled
                      name="jobProfile"
                      placeholder="Job Profile"
                      defaultValue={createDriveFields?.jobProfile}
                    />
                  </div>
                  <div className="flex flex-col gap-y-2 w-1/2">
                    <Label
                      className="text-black font-semibold"
                      htmlFor="contactName"
                    >
                      Contact Name
                    </Label>
                    <Input
                      disabled
                      name="contactName"
                      placeholder="Company Address"
                      defaultValue={createDriveFields?.contactName}
                    />
                  </div>
                </div>
                <div className="flex items-center gap-x-4 w-full">
                  <div className="flex flex-col gap-y-2 w-1/2">
                    <Label
                      className="text-black font-semibold"
                      htmlFor="contactEmail"
                    >
                      Contact Email
                    </Label>
                    <Input
                      disabled
                      name="contactEmail"
                      placeholder="Contact Email"
                      defaultValue={createDriveFields?.contactEmail}
                    />
                  </div>
                  <div className="flex flex-col gap-y-2 w-1/2">
                    <Label
                      className="text-black font-semibold"
                      htmlFor="contactNumber"
                    >
                      Contact Number
                    </Label>
                    <Input
                      disabled
                      name="contactNumber"
                      placeholder="Company Number"
                      defaultValue={createDriveFields?.contactNumber}
                    />
                  </div>
                </div>
                <div className="flex items-center gap-x-4 w-full">
                  <div className="flex flex-col gap-y-2 w-1/2">
                    <Label
                      className="text-black font-semibold"
                      htmlFor="startDate"
                    >
                      Drive Start Date <span className="text-[#DC2327] text-center">*</span>
                    </Label>

                    <input
                      type="date"
                      id="start-date"
                      class="calendar-input border p-2"
                      value={startDate}
                      onChange={(e) => {
                        const selectedStartDate = e.target.value;
                        setStartDate(selectedStartDate); // Update start date state
                        // Clear the end date if it's before the new start date
                        if (
                          endDate &&
                          selectedStartDate &&
                          selectedStartDate > endDate
                        ) {
                          setEndDate(""); // Reset the end date if it's before the start date
                        }
                      }}
                      min ={today}  max={endDate ? subtractOneDay(endDate) : null} // Ensure start date is before end date
                      placeholder="Drive Start Date"
                    />
                  </div>

                  <div className="flex flex-col gap-y-2 w-1/2">
                    <Label
                      className="text-black font-semibold"
                      htmlFor="endDate"
                    >
                      Drive End Date <span className="text-[#DC2327] text-center">*</span>
                    </Label>

                    <input
                      type="date"
                      id="end-date"
                      class="calendar-input border p-2"
                      value={endDate}
                      onChange={(e) => {
                        const selectedEndDate = e.target.value;
                        setEndDate(selectedEndDate); // Update end date state
                      }}
                      min={startDate ? addOneDay(startDate) :  addOneDay(today)} // Ensure end date is after start date
                      placeholder="Drive Start Date"
                    />
                  </div>
                </div>
                <div className="flex items-center gap-x-4 w-full">
                  <div className="flex flex-col gap-y-2 w-1/2">
                    <Label
                      className="text-black font-semibold"
                      htmlFor="graduatingYear"
                    >
                      Graduating Year <span className="text-[#DC2327] text-center">*</span>
                    </Label>
                    <Input
                      onChange={handleChange}
                      name="graduatingYear"
                      placeholder="Graduating Year"
                      defaultValue={createDriveFields?.graduatingYear}
                    />
                  </div>
                  <div className="flex flex-col gap-y-2 w-1/2">
                    <Label
                      className="text-black font-semibold"
                      htmlFor="ctcOffered"
                    >
                      Ctc Offered
                    </Label>
                    <Input
                      disabled
                      name="ctcOffered"
                      placeholder="Ctc Offered"
                      defaultValue={createDriveFields?.ctcOffered}
                    />
                  </div>
                </div>

                <div className="flex flex-col gap-y-2">
                  <Label
                    className="text-black font-semibold"
                    htmlFor="description"
                  >
                    Job Description
                  </Label>
                  <Textarea
                    disabled
                    defaultValue={createDriveFields?.description}
                    onChange={handleChange}
                    name="description"
                    placeholder="Enter job description"
                  />
                </div>

                <div className="flex flex-col gap-y-2">
                  <Label
                    className="text-black font-semibold"
                    htmlFor="eligibilityCriteria"
                  >
                    Eligibility Criteria
                  </Label>
                  <Textarea
                    disabled
                    defaultValue={createDriveFields?.eligibilityCriteria}
                    onChange={handleChange}
                    name="eligibilityCriteria"
                    placeholder="Enter eligibility criteria"
                  />
                </div>

                <Accordion type="single" collapsible className="w-full">
                  {roundFields.map((round, i) => {
                    return (
                      <AccordionItem key={i} value={`item-${i + 1}`}>
                        <AccordionTrigger>{round.name}</AccordionTrigger>
                        <AccordionContent>
                          <div className="flex flex-col gap-y-3">
                            <div className="flex flex-col gap-y-1">
                              <p className="text-black text-base font-semibold">
                                Round Status
                              </p>
                              <div className="flex items-center gap-x-2">
                              {round.statuses.map((status, i) => {
                    return (
                                    <Badge variant={"primary"} key={i}>
                                      {status}
                                    </Badge>
                                  );
                                })}
                              </div>
                            </div>
                            <div className="flex flex-col gap-y-1">
                              <p className="text-black text-base font-semibold">
                                Applicant Status
                              </p>
                              <div className="flex items-center gap-x-2">
                              {round.applicantStatuses.map((applicant, i) => {
                    return (
                                    <Badge variant={"primary"} key={i}>
                                      {applicant}
                                    </Badge>
                                  );
                                })}
                                    
                              </div>
                            </div>
                          </div>
                        </AccordionContent>
                      </AccordionItem>
                    );
                  })}
                </Accordion>

                <Dialog
                  open={roundsDialogOpen}
                  onOpenChange={setRoundsDialogOpen}
                >
                  <DialogTrigger>
                    <Button variant="primary">Add More Rounds</Button>
                  </DialogTrigger>
                  <DialogContent>
                    <DialogHeader>
                      <DialogTitle>Enter Round Details</DialogTitle>
                      <DialogDescription>
                        <div className="flex flex-col gap-y-4">
                          <div className="flex flex-col gap-y-2">
                            <Label
                              className="text-black font-semibold"
                              htmlFor="roundName"
                            >
                              Round Name
                            </Label>
                            <Input
                              value={roundName}
                              onChange={(e) => setRoundName(e.target.value)}
                              name="roundName"
                              placeholder="Enter the round name"
                            />
                          </div>
                          <div className="flex flex-col gap-y-2">
                            <Label
                              className="text-black font-semibold"
                              htmlFor="roundStatus"
                            >
                              Round Status
                            </Label>
                            <Input
                              value={roundStatus}
                              onChange={(e) => setRoundStatus(e.target.value)}
                              onKeyDown={(e) =>
                                e.key === "Enter" && handleAddRoundStatus()
                              }
                              name="roundStatus"
                              placeholder="Enter round status"
                            />
                          </div>
                          <div className="flex items-center gap-x-2 gap-y-1 flex-wrap">
                            {statuses.map((status, i) => (
                              <Badge variant={"primary"} key={i}>
                                {status}
                              </Badge>
                            ))}
                          </div>
                          <div className="flex flex-col gap-y-2">
                            <Label
                              className="text-black font-semibold"
                              htmlFor="roundStatus"
                            >
                              Applicant Status
                            </Label>
                            <Input
                              value={roundApplicantStatus}
                              onChange={(e) =>
                                setRoundApplicantStatus(e.target.value)
                              }
                              onKeyDown={(e) =>
                                e.key === "Enter" &&
                                handleAddRoundApplicantStatus()
                              }
                              name="roundStatus"
                              placeholder="Enter Applicant status"
                            />
                          </div>
                          <div className="flex items-center gap-x-2 gap-y-1 flex-wrap">
                            {applicantStatuses.map((applicantStatus, i) => (
                              <Badge variant={"primary"} key={i}>
                                {applicantStatus}
                              </Badge>
                            ))}
                          </div>
                        </div>
                      </DialogDescription>
                    </DialogHeader>

                    <DialogFooter className="flex items-center w-full">
                      <Button
                        onClick={() => {
                          setRoundsDialogOpen(false);
                          handleClose();
                        }}
                        variant="outlined"
                        className="py-1 rounded-2xl px-6"
                        type="submit"
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={() => {
                          handleAddRound();
                          setRoundsDialogOpen(false);
                        }}
                        variant="outlined"
                        className="py-1 rounded-2xl px-6"
                        type="submit"
                      >
                        Finish
                      </Button>
                      <Button
                        variant="primary"
                        onClick={handleAddRound}
                        className="py-1 rounded-2xl px-6"
                        type="submit"
                      >
                        Add More Rounds
                      </Button>
                    </DialogFooter>
                  </DialogContent>
                </Dialog>
              </div>
            </DialogDescription>
          </DialogHeader>

          <DialogFooter className="flex items-center w-full sticky bottom-0">
            <Button
              onClick={() => setOpen(false)}
              variant="outlined"
              className="py-1 rounded-2xl px-6"
              type="submit"
            >
              Cancel
            </Button>
            {loading ? (
              <Button
                disabled={loading}
                variant="primary"
                className="py-1 rounded-2xl px-6"
                type="submit"
              >
                <Loader className="animate-spin" />
                Creating Drive
              </Button>
            ) : (
              <Button
                onClick={handleCreateDrive}
                variant="primary"
                className="py-1 rounded-2xl px-6"
                type="submit"
              >
                Create Drive
              </Button>
            )}
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default CreateDriveModal;
