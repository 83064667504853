import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { fetchJobFormDetail } from "../../redux/features/jobFormDetailSlice"
import { useEffect } from "react"
import { JobDetailHeader } from "./JobDetailHeader"
import KeyValuedetails from "../students/KeyValuedetails"
import { DataTable } from "../reusable/table/DataTable"
import { TargetPoolColumns } from "./JobTable/TargetPoolColumns"
import { Skeleton } from "../ui/skeleton"

const JobDetail = () => {

  const { job: jobData, loading } = useSelector((state) => state.jobFormDetailReducer)
  const { jobId } = useParams()
  const dispatch = useDispatch()
  const job = jobData?.[0]?.job_form

  useEffect(() => {
    dispatch(fetchJobFormDetail(jobId))
  }, [jobId])

  if (loading || !job) {
    
    return (
      <div className="px-4 mt-3 space-y-6">
     
      <div className="grid grid-rows-1 grid-cols-8 gap-x-10 grid-flow-col gap-y-4 h-40">
        <div className="rounded-lg border-2 shadow-lg px-6 py-4 row-span-1 col-span-4">
          <Skeleton className="w-full h-full" />
        </div>
        <div className="rounded-lg border-2 shadow-lg px-6 py-4 row-span-1 col-span-4">
          <Skeleton className="w-full h-full" />
        </div>
      </div>
    
      <div className="grid grid-rows-1 grid-cols-8 gap-x-10 grid-flow-col gap-y-4 h-48">
        <div className="rounded-lg border-2 shadow-lg px-6 py-4 row-span-1 col-span-4">
          <Skeleton className="w-full h-full" />
        </div>
        <div className="rounded-lg border-2 shadow-lg px-6 py-4 row-span-1 col-span-4">
          <Skeleton className="w-full h-full" />
        </div>
      </div>
    
      <div className="grid grid-cols-6 gap-x-10 grid-flow-col gap-y-4 h-80">
        <div className="rounded-lg border-2 shadow-lg px-6 py-4 row-span-3 col-span-3">
          <Skeleton className="w-full h-full" />
        </div>
        <div className="rounded-lg border-2 shadow-lg px-6 py-4 row-span-3 col-span-3">
          <Skeleton className="w-full h-full" />
        </div>
      </div>
    </div>
    
    )
  }

  return (
    <div className="px-4 mt-3 space-y-6">
      <JobDetailHeader data={job} />

      <div className="grid grid-cols-2 gap-x-10">
       
        <div className="rounded-lg border-2 shadow-lg px-6 py-4">
          <h2 className="text-primaryButtonColor font-bold text-lg mb-2">Point of Contact</h2>
          <KeyValuedetails title={"Name"} value={job.contact_name || "-"} />
          <KeyValuedetails title={"Email"} value={job.contact_email ?
            <a className="underline" href={`mailto:${job.contact_email}`}>{job.contact_email}</a> : "-"} />
          <KeyValuedetails title={"Phone"} value={job.contact_phone_number || "-"} />
        </div>

        <div className="rounded-lg border-2 shadow-lg px-6 py-4">
          <h2 className="text-primaryButtonColor font-bold text-lg mb-2">Company Details</h2>
          <KeyValuedetails title={"Sector"} value={job.sector || "-"} />
          <KeyValuedetails title={"Annual Turnover"} value={job.annual_turnover ?
            new Intl.NumberFormat().format(job.annual_turnover) : "-"} />
          <KeyValuedetails title={"Address"} value={job.address || "-"} />
        </div>
      </div>

      <div className="grid grid-cols-2 gap-x-10">

        <div className="rounded-lg border-2 shadow-lg px-6 py-4">
          <h2 className="text-primaryButtonColor font-bold text-lg mb-2">Opening Details</h2>
          <div className="space-y-2">
            <KeyValuedetails title={"Job Profile"} value={job.job_profile || "-"} />
            <KeyValuedetails title={"Designation"} value={job.designation || "-"} />
            <KeyValuedetails title={"CTC Offered"} value={job.ctc_offered ?
              new Intl.NumberFormat().format(job.ctc_offered) + "/year" : "-"} />
            <KeyValuedetails title={"Branch"} value={job.branch || "-"} />
            <KeyValuedetails title={"Preffered Mode"} value={job.mode_of_recruitment || "-"} />
            <KeyValuedetails title={"Location of Posting"} value={job.location_of_posting || "-"} />
          </div>
        </div>

        <div className="rounded-lg border-2 shadow-lg px-6 py-4">
          <h2 className="text-primaryButtonColor font-bold text-lg mb-2">Selection Procedure</h2>
          <div className="space-y-3">
            {job.selection_procedure.map((round, i) => (
              <div key={i} className="rounded-xl border shadow-md px-4 py-1 text-md font-bold">
                {round}
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="grid grid-cols-2 gap-x-10">
  
        <div className="rounded-lg border-2 shadow-lg px-6 py-4">
          <h2 className="text-primaryButtonColor font-bold text-lg mb-2">Target Pool</h2>
          {job.target_pool && <DataTable hasPagination={false} hasClick={false} columns={TargetPoolColumns} data={job.target_pool} />}
        </div>

        <div className="rounded-lg border-2 shadow-lg px-6 py-4">
          <h2 className="text-primaryButtonColor font-bold text-lg mb-2">Additional Information</h2>
          {jobData?.[0]?.additional_fields?.length > 0 ? (
            <div className="space-y-2">
              {jobData[0].additional_fields.map((field) => (
                <KeyValuedetails
                  key={field.id}
                  title={field.label}
                  value={
                    field.field_type === "boolean"
                      ? field.value === "true" ? "Yes" : "No"
                      : field.value || "-"
                  }
                />
              ))}
            </div>
          ) : (
            <p className="text-gray-500">No additional fields available.</p>
          )}
        </div>
      </div>
    </div>
  )
}

export default JobDetail
