import { Skeleton } from "../ui/skeleton"

const DriveDetailLoading = () => {
    return (
        <div className="flex flex-col gap-y-4">
            {/* First Skeleton Row */}
            <div className="flex items-center gap-x-6">
                <Skeleton className="w-2/3 h-28" />
                <Skeleton className="w-1/3 h-28" />
            </div>
            
            {/* Second Skeleton Row (with rounded full skeletons) */}
            <div className="flex items-center gap-x-6">
                {
                    [1, 2, 3, 4].map((_, i) => (
                        <Skeleton key={i} className="w-40 h-40 rounded-full" />
                    ))
                }
            </div>
            
            {/* Third Skeleton Row (with two items) */}
            <div className="flex items-center gap-x-6 w-full">
                {
                    [1, 2].map((_, i) => (
                        <Skeleton key={i} className="w-1/2 h-60" />
                    ))
                }
            </div>
            
            {/* Fourth Skeleton Row (Full-screen width) */}
            <div className="flex items-center gap-x-6 w-full">
                <Skeleton className="w-full h-60" />
            </div>
        </div>
    )
}

export default DriveDetailLoading;
