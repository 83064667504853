import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import axiosInstance from "../../headersService";

export const fetchCompanyDetails = createAsyncThunk(
  "fetchCompanyDetails",
  async (obj, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/company/${obj.id}`
      );

      return data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);

export const companyDetailSlice = createSlice({
  name: "companyDetail",
  initialState: {
    loading: false,
    company: {},
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCompanyDetails.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchCompanyDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.company = action.payload;
    });
    builder.addCase(fetchCompanyDetails.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export default companyDetailSlice.reducer;
