import {
    Sheet,
    SheetContent,
    SheetDescription,
    SheetHeader,
    SheetTitle,
    SheetTrigger,
} from "../../ui/sheet"

const CommonSheet = ({ open, setOpen, className, title, children }) => {
    return (
        <Sheet open={open} onOpenChange={setOpen}>
            <SheetContent className={className}>
                <SheetHeader>
                    <SheetTitle>{title}</SheetTitle>
                    {children}
                </SheetHeader>
            </SheetContent>
        </Sheet>
    )
}
export default CommonSheet