import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../../styles/Login.css";
import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux";
import { resetLoginError, resetLoginSucess, userLogin } from "../../redux/features/loginSlice";
import { Button } from '../ui/button';
import logo from '../../assets/logo.png';
import ButtonLoading from "../reusable/loaders/button-loading";
import InputFormField from "../reusable/formfields/input-form-field";
import { Form } from "../ui/form";
import { toast } from "sonner";

const formSchema = z.object({
  email: z.string().email(),
  password: z.string(),
})

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const { loginSuccessMsg, error, loading, user } = useSelector((state) => state.loginReducer)
  const [loginAttempt, setLoginAttempt] = useState()
  const onSubmit = async (values) => {
    setLoginAttempt(true);
    dispatch(userLogin(values))
  };

  const handleSuccess = () => {
    toast.success(loginSuccessMsg)
    dispatch(resetLoginSucess())
  }
  const handleError = () => {
    toast.error(error)
    dispatch(resetLoginError())
  }

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  })

  useEffect(() => {
    if (user) {
      navigate("/");
    } if (loginSuccessMsg && loginAttempt) {
      handleSuccess()
    } if (error) {
      handleError()
    }
  }, [user, loginSuccessMsg, error]);

  return (
    <div className="flex items-center justify-center h-screen">
      <div className="shadow-lg border rounded-2xl px-6 py-6">
        <div className="flex flex-col items-center gap-y-4 justify-center">
          <img src={logo} className="max-w-[50%]" alt="" />
          <h1 className="text-primaryButtonColor font-bold text-xl">WELCOME BACK</h1>
        </div>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4 mt-6">
            <InputFormField name="email" control={form.control} placeholder={"Enter your Email"} label={"Email"} type={"email"} />
            <InputFormField name="password" control={form.control} placeholder={"Enter your Password"} label={"Password"} type={"password"} />
            <Link to="/forgot-password"><p className="text-primaryButtonColor text-sm cursor-pointer hover:underline">Forgot Password?</p></Link>
            <Button type="submit" variant="primary" className="w-full mt-4 py-5 text-base">
              {
                loading ? <ButtonLoading /> : "Submit"
              }
            </Button>

          </form>
        </Form>
      </div>
    </div>
  );
};

export default Login;
