import { useForm } from "react-hook-form";
import CommonSheet from "../../reusable/sheet/common-sheet";
import { Form } from "../../ui/form";
import { zodResolver } from "@hookform/resolvers/zod";
import { additionalFieldSchema } from "../../../schemas/additional-field-schema";
import InputFormField from "../../reusable/formfields/input-form-field";
import SwitchFormField from "../../reusable/formfields/switch-form-field";
import SelectFormField from "../../reusable/formfields/select-form-field";
import { Button } from "../../ui/button";
import { useDispatch } from "react-redux";
import { addAdditionalField } from "../../../../src/redux/features/job-form/additionalFieldSlice";

const fieldTypeItems = [
    // { label: "Positive Number", value: "positive_number" },
    // { label: "Non Negative Number", value: "non_negative_number" },
    // { label: "Positive Integer", value: "positive_integer" },
    // { label: "Non Negative Integer", value: "non_negative_integer" },
    { label: "Text", value: "string" }
    // { label: "Non Empty Text", value: "non_empty_string" },
    // { label: "Float", value: "float" },
    // { label: "Integer", value: "integer" },
    // { label: "Boolean", value: "boolean" },
];

const validateDefaultValue = (fieldType, value) => {
    const numValue = Number(value);
    switch (fieldType) {
        case "positive_number":
            return !isNaN(numValue) && numValue > 0 || "Default value must be a positive number";
        case "non_negative_number":
            return !isNaN(numValue) && numValue >= 0 || "Default value must be a non-negative number";
        case "positive_integer":
            return Number.isInteger(numValue) && numValue > 0 || "Default value must be a positive integer";
        case "non_negative_integer":
            return Number.isInteger(numValue) && numValue >= 0 || "Default value must be a non-negative integer";
        case "string":
            return typeof value === "string" || "Default value must be a string";
        case "non_empty_string":
            return value.trim() !== "" || "Default value must be a non-empty string";
        case "float":
            return !isNaN(numValue) || "Default value must be a valid float";
        case "integer":
            return Number.isInteger(numValue) || "Default value must be an integer";
        case "boolean":
            return value === "true" || value === "false" || "Default value must be true or false";
        default:
            return true;
    }
};

const AdditionalFieldSheet = ({ open, setOpen }) => {
    const dispatch = useDispatch();
    const form = useForm({
        resolver: zodResolver(additionalFieldSchema),
        defaultValues: {
            label: "",
            required: false,
            default_value: "",
            field_type: "",
        },
    });

    const { watch, setError, clearErrors } = form;
    const fieldType = watch("field_type");
    const defaultValue = watch("default_value");

    const onSubmit = (values) => {
        const validationMessage = validateDefaultValue(values.field_type, values.default_value);
        if (validationMessage !== true) {
            setError("default_value", { type: "manual", message: validationMessage });
            return;
        }
        clearErrors("default_value");

        const processedValues = {
            ...values,
            default_value: processDefaultValue(values.field_type, values.default_value)
        };

        dispatch(addAdditionalField(processedValues));
        form.reset({
            label: "",
            required: false,
            default_value: "",
            field_type: "",
        });
    };
     const processDefaultValue = (fieldType, value) => {
        switch (fieldType) {
            case "positive_number":
            case "non_negative_number":
            case "positive_integer":
            case "non_negative_integer":
            case "float":
            case "integer":
                return Number(value);
            case "boolean":
                return value === "true";
            default:
                return value;
        }
    };

    return (
        <CommonSheet open={open} setOpen={setOpen} title={"Add Additional fields"}>
            <Form {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-y-4">
                    <InputFormField
                        name={"label"}
                        placeholder={"Enter label for field"}
                        label={"Label"}
                        control={form.control}
                        type={"text"}
                        mandatory
                    />
                    <SwitchFormField
                        name={"required"}
                        control={form.control}
                        label={"Required"}
                        className={"flex flex-col w-full gap-y-1"}
                    />
                    <SelectFormField
                        name={"field_type"}
                        items={fieldTypeItems}
                        label={"Field Type"}
                        placeholder={"Select the type of field"}
                        control={form.control}
                    />
                    <InputFormField
                        name={"default_value"}
                        placeholder={"Enter default value"}
                        label={"Default Value"}
                        control={form.control}
                        type={"text"}
                        defaultValue={""}
                    />
                    <div className="flex items-center gap-x-4 absolute bottom-6 w-[88%]">
                        <Button type="button" className="w-1/2" variant="outline" onClick={() => setOpen(false)}>
                            Cancel
                        </Button>
                        <Button type="submit" className="w-1/2" variant="primary">
                            Add
                        </Button>
                    </div>
                </form>
            </Form>
        </CommonSheet>
    );
};

export default AdditionalFieldSheet;
