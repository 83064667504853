import { useDispatch, useSelector } from "react-redux"
import CommonModal from "./reusable/modals/CommonModal"
import { Copy, Loader, Share2 } from "lucide-react"
import { useEffect, useState } from "react"
import { Button } from "./ui/button"
import { Link } from "react-router-dom"
import AdditionalFieldSheet from "./jobs/sheet/additional-field-sheet"
import {
    Accordion,
    AccordionContent,
    AccordionItem,
    AccordionTrigger,
} from "./ui/accordion"
import { Label } from "./ui/label"
import { createAdditionalFields, resetError, resetSuccessMsg } from "../redux/features/job-form/additionalFieldSlice"
import { toast } from "sonner"
import { Input } from "./ui/input"

const SharingModal = () => {

    const [isCopied, setIsCopied] = useState(false)
    const { user } = useSelector(state => state.loginReducer)
    const [sheetOpen, setSheetOpen] = useState(false)
    const [jobFormInstanceSuccessMsg, setJobFormInstanceSuccessMsg] = useState(null)
    const jobFormLink = `${process.env.REACT_APP_COMPANY_URL}/job-form`
    const dispatch = useDispatch()
    const { additionalFields, successMsg, error, loading: isLoading } = useSelector((state) => state.additionalFieldReducer)
    const copyLink = (jobFormInstanceId) => {
        navigator.clipboard.writeText(jobFormLink + `/${jobFormInstanceId}`)
        setIsCopied(true)

        setTimeout(() => {
            setIsCopied(false)
        }, 2000);
    }

    const handleSubmit = () => {
        dispatch(createAdditionalFields(additionalFields))
    }

    useEffect(() => {
        if (successMsg) {
            toast.success("Click on Copy to share your form")
            setJobFormInstanceSuccessMsg(successMsg)
            dispatch(resetSuccessMsg())
        }
        if (error) {
            toast.error(error)
            dispatch(resetError())
        }
    }, [successMsg, error])

    const triggerButton = <Button variant="primary" className="inline-flex items-center gap-x-2">
        <Share2 size={16} />
        Share
        </Button>
    return (
        <div>
            <CommonModal title={"Share Job form"} triggerButton={triggerButton} className={"overflow-y-auto max-h-[70%]"}>
                {
                    jobFormInstanceSuccessMsg ?
                        <div className="flex items-center gap-x-4">
                            <div className="w-[70%] h-full flex items-center px-4 text-sm text-primaryTextColorLight border border-l-0 border-t-0 border-b-0 border-r">
                                <p className="line-clamp-1">{jobFormLink}/{jobFormInstanceSuccessMsg?.jobFormInstanceId}</p>
                            </div>
                            <div onClick={() => copyLink(jobFormInstanceSuccessMsg.jobFormInstanceId)} className="w-[30%] h-full flex items-center justify-center font-medium text-secondaryTextColor cursor-pointer gap-x-2 ">
                                <Copy size={16} />
                                <p className="text-sm">{isCopied ? "Copied" : "Copy"}</p>
                            </div>
                        </div> :
                        additionalFields && additionalFields.length > 0 ?

                            <div>
                                {
                                    additionalFields.map((field, i) => {
                                        return (
                                            <Accordion type="single" collapsible>
                                                <AccordionItem value={`item-${i}`}>
                                                    <AccordionTrigger>{field.label}</AccordionTrigger>
                                                    <AccordionContent>
                                                        <div className="flex items-start gap-x-4">
                                                            <div className="w-1/2 flex flex-col gap-y-2">
                                                                <div>
                                                                    <Label>Type of Field :</Label>
                                                                    <p>{field.field_type}</p>
                                                                </div>
                                                                <div>
                                                                    <Label>Default Value :</Label>
                                                                    <p>{field.default_value}</p>
                                                                </div>
                                                            </div>
                                                            <div className="w-1/2 flex flex-col gap-y-2">
                                                                <div>
                                                                    <Label>Required :</Label>
                                                                    <p>{field.required ? "YES" : 'NO'}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </AccordionContent>
                                                </AccordionItem>
                                            </Accordion>

                                        )
                                    })
                                }
                            </div> :
                            <>
                                <div>
                                    Click on additional fields to add any additional fields you want the company to fill
                                </div>
                                <div>
                                    Click on Share form to share the form
                                </div>
                            </>
                }
                {
                    !jobFormInstanceSuccessMsg && <div className="flex justify-end items-center gap-x-4">
                        <Button onClick={() => setSheetOpen(true)} variant="secondary">
                            Add additional Fields
                        </Button>
                        <Button disabled={isLoading} onClick={handleSubmit} variant="primary">
                            {isLoading ? <Loader className="animate-spin" /> : "Share Form"}
                        </Button>
                    </div>
                }
            </CommonModal >
            <AdditionalFieldSheet open={sheetOpen} setOpen={setSheetOpen} />
        </div >
    )
}
export default SharingModal