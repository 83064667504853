import React, { useContext, useEffect, useRef, useState } from "react";
import { Context } from "../../context/Context";
import { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllStudents } from "../../redux/features/studentSlice";
import { MdFilterAlt } from "react-icons/md";

import { setFilter } from "../../redux/features/BranchAndYearTrackSlice";

import {
  addStudentsSheet,
  resetError,
  resetStudentsAddSuccess,
} from "../../redux/features/addStudentsSheet";
import { handleSnackbar } from "../../redux/features/snackbarSlice";
import {
  updateOffset,
  updatePage,
  updateSearchName,
  updateView,
} from "../../redux/features/studentViewSlice";
import { Input } from "../ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "../ui/sheet";

import sampleStudentExcel from "../../assets/templates/student-data-upload-template.xlsx";
import { Button } from "../ui/button";
import CompanyLoading from "../Companies/CompanyLoading";
import StudentTable from "./studentTable/StudentTable";
import { StudentColumns } from "./studentTable/StudentColumns";
import FileUpload from "./FileUpload";
import { toast } from "sonner";

import { ChevronRight, Search } from "lucide-react";
import { Icon, Icons } from "../ui/icon";
import { Label } from "../ui/label";
import { downloadStudentSheet } from "../../../src/redux/features/students/downloadStudentSheet";
import { getCollegeBranches } from "../../redux/features/getCollegeBranch";
import { useLocation, useNavigate } from "react-router-dom";

import { DataTable } from "../reusable/table/DataTable";
import { Switch } from "../ui/switch";
import CreateAnnouncement from "../Announcement/CreateAnnouncement";

const StudentList = () => {
  const [pagination, setPagination] = useState({
    pageSize: 10,
    pageIndex: 0,
    pageIndex: 0,
  });

  const {
    branch: branchFilter,
    cgpa: cgpaFilter,
    year: yearFilter,
    placed: placedFilter,
    dreamPlacement: dreamPlacementFilter,
    liveKT: liveKTFilter,
    totalKT: totalKTFilter,
  } = useSelector((state) => state.studentBranchYear);

  const { user } = useSelector((state) => state.loginReducer);
  const { successMessage, error, loading } = useSelector(
    (state) => state.studentsSheetReducer
  );
  const { loading: studentSheetLoading } = useSelector(
    (state) => state.downloadStudentSheetReducer
  );
  const [year, setYear] = useState(yearFilter);
  const [branch, setBranch] = useState(branchFilter);
  const [placed, setPlaced] = useState(placedFilter);
  const [dreamPlacement, setDreamPlacement] = useState(dreamPlacementFilter);
  const [cgpa, setCgpa] = useState(cgpaFilter);
  const [liveKT, setLiveKT] = useState(liveKTFilter);
  const [totalKT, setTotalKT] = useState(totalKTFilter);
  //state for checking if filters are applied or not
  const [areFiltersApplied, setAreFiltersApplied] = useState(true);
  //pending state
  const [pendingFilters, setPendingFilters] = useState({});

  const recentYears = [
    new Date().getFullYear() - 1,
    new Date().getFullYear(),
    new Date().getFullYear() + 1,
    new Date().getFullYear() + 2,
    new Date().getFullYear() + 3,
    new Date().getFullYear() + 4,
  ];

  const gpas = [9, 8, 7, 6, 5, 4];

  const backlog = [0, 1, 2, 3, 4];
  const { search, setSearch, toggleOn } = useContext(Context);
  const { openMenu } = useSelector((state) => state.openMenu);
  const excelFileRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);

  const [openDialog, setOpenDialog] = useState(false); // Separate state for dialog
  const [isSheetOpen, setIsSheetOpen] = useState(false);

  const handleClose = () => {
    setOpenDialog(false);
    setSelectedFile(null);
  };

  const navigate = useNavigate();

  const dispatch = useDispatch();
  //after the fetchall students the students are set using redux and we can access it using useselector
  const { students, loading: studentLoading } = useSelector(
    (state) => state.getAllStudents
  );

  //taking only the result array from the students
  const { view, limit, offset, searchName, page } = useSelector(
    (state) => state.studentViewReducer
  );
  const { branch: branchData } = useSelector(
    (state) => state.collegeBranchReducer
  );
  const timeoutRef = useRef(null);

  // dialog filtering
  const filters = useSelector((state) => state.studentBranchYear);

  const handleFilterChange = (key) => (value) => {
    setPendingFilters((prev) => ({
      ...prev,
      [key]: value,
    }));
    setAreFiltersApplied(false);
  };

  const { pageSize, pageIndex } = pagination;

  const handleApplyFilters = () => {
    //dispatching all pending filters
    Object.entries(pendingFilters).forEach(([key, value]) => {
      dispatch(setFilter({ key, value }));
    });
    setPendingFilters({});
    setAreFiltersApplied(true);
    setPagination({ pageSize: pageSize, pageIndex: 0 });
  };

  const location = useLocation();
  const { yearFilter: locationYear, placed: locationPlaced } =
    location.state || {};

  useEffect(() => {
    const { pageSize, pageIndex } = pagination;
    const queryFilters = areFiltersApplied ? filters : {};
    if (locationYear || locationPlaced) {
      const query = {
        type: "Student",
        type: "Student",
        year: locationYear,
        placed: locationPlaced ? locationPlaced : undefined, // Only include placed if it exists
        limit: pageSize,
        offset: pageSize * pageIndex,
        placed: locationPlaced ? locationPlaced : undefined, // Only include placed if it exists
        limit: pageSize,
        offset: pageSize * pageIndex,
      };

      dispatch(fetchAllStudents(query));

      dispatch(getCollegeBranches());
    } else {
      //function that calls fetchAllstudents when the page loads
      dispatch(
        fetchAllStudents({
          type: "Student",
          ...queryFilters,
          limit: pageSize,
          offset: pageSize * pageIndex,
        })
      );
      dispatch(getCollegeBranches());
    }
  }, [
    pagination,
    filters, // Adding filters as a dependency
    locationYear,
    locationPlaced,
  ]);

  const handleSearchChange = (e) => {
    dispatch(updateSearchName(e.target.value));

    const debounceDelay = 500;

    // Clear previous timeout if exists
    if (timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      handleSearchChange.timeoutId = setTimeout(() => {
        dispatch(
          fetchAllStudents({
            type: "Student",
            name: e.target.value,
          })
        );
      }, debounceDelay);
    }, debounceDelay);
  };

  const handleSelectedFile = (e) => {
    setSelectedFile(e.target.files[0]);
    setOpenDialog(true);
  };

  const clearSearch = () => {
    dispatch(updateSearchName(""));
    dispatch(
      fetchAllStudents({
        type: "Student",
      })
    );
  };

  useEffect(() => {
    if (successMessage) {
      toast.success(successMessage);
      dispatch(resetStudentsAddSuccess());
    }
    if (error) {
      toast.error(error);
      dispatch(resetError());
    }
  }, [successMessage, error]);

  const handleDownloadStudentSheet = () => {
    dispatch(
      downloadStudentSheet({
        name: searchName,
        // year,
        // placed,
        // dreamPlacement,
        // branch,
        ...filters,
      })
    )
      .unwrap()
      .then(() => {
        toast.success("Sheet downloaded successfully!");
      })
      .catch((error) => {
        toast.error("Failed to download the sheet. Please try again.");
      })
      .unwrap()
      .then(() => {
        toast.success("Sheet downloaded successfully!");
      })
      .catch((error) => {
        toast.error("Failed to download the sheet. Please try again.");
      });
  };

  useEffect(() => {
    return () => {
      setAreFiltersApplied(true);
      setPendingFilters({});
    };
  }, []);

  return (
    <div className="mt-3">
      <div className="flex flex-col gap-y-4">
        <div className="flex justify-between items-center">
          <div className="flex items-center gap-x-4">
            <h1 className="w-1/2 text-2xl font-bold h-full self-end">
              Student Database
            </h1>
            <div className="flex bg-searchBgColor active:border-primaryTextColor items-center justify-between border rounded-lg px-2 ">
              <Input
                onChange={handleSearchChange}
                className="font-light bg-transparent text-base border-none"
                placeholder="Search Students..."
              />
              <Search size={20} />
            </div>
          </div>

          <div className="flex items-center gap-x-4">
            <FileUpload />
            <div className="flex items-center mt-4">
              <a
                href={sampleStudentExcel}
                download="student-upload-template.xlsx"
              >
                <Button
                  variant="secondary"
                  className="rounded-tl-3xl rounded-bl-3xl rounded-tr-none rounded-br-none"
                >
                  <Icon icon={Icons.DOWNLOAD} className="bg-primaryTextColor" />
                  Download Excel Template
                </Button>
              </a>
              {studentSheetLoading ? (
                <Button
                  disabled
                  variant="secondary"
                  className="rounded-tr-3xl rounded-br-3xl rounded-tl-none rounded-bl-none"
                >
                  <Icon icon={Icons.DOWNLOAD} className="bg-primaryTextColor" />
                  Downloading
                </Button>
              ) : (
                <Button
                  onClick={() => handleDownloadStudentSheet()}
                  variant="secondary"
                  className="rounded-tr-3xl rounded-br-3xl rounded-tl-none rounded-bl-none"
                >
                  <Icon icon={Icons.DOWNLOAD} className="bg-primaryTextColor" />
                  Download Student Sheet
                </Button>
              )}
            </div>
          </div>
        </div>
        {/* //Dialog box for filter */}
        <div className="flex items-center gap-2">
          <Sheet open={isSheetOpen} onOpenChange={setIsSheetOpen}>
            <SheetTrigger>
              <Button
                variant="primary"
                className="rounded-3xl text-sm text-[17px]"
              >
                <MdFilterAlt className="text-xl" />{" "}
                <span className="ml-1">Filter</span>
              </Button>
            </SheetTrigger>
            <SheetContent className="max-h-screen overflow-y-auto">
              <SheetHeader>
                <SheetTitle>Filter</SheetTitle>
              </SheetHeader>
              <div className="flex flex-col gap-y-4 p-4">
                {user.branch.length == 0 && (
                  <div className="flex flex-col gap-y-1">
                    <Label>Branch</Label>
                    <Select
                      id="branch"
                      onValueChange={handleFilterChange("branch")}
                      defaultValue={filters.branch}
                    >
                      <SelectTrigger className="w-full">
                        <SelectValue placeholder="Select Branch" />
                      </SelectTrigger>
                      <SelectContent>
                        {branchData?.map((branch, i) => (
                          <SelectItem key={i} value={branch}>
                            {branch}
                          </SelectItem>
                        ))}
                        <SelectItem value={null}>None</SelectItem>
                      </SelectContent>
                    </Select>
                  </div>
                )}

                <div className="flex flex-col gap-y-1">
                  <Label>Year</Label>
                  <Select
                    onValueChange={handleFilterChange("year")}
                    defaultValue={filters.year}
                  >
                    <SelectTrigger className="w-full">
                      <SelectValue placeholder="Year" />
                    </SelectTrigger>
                    <SelectContent>
                      {recentYears.map((item, i) => (
                        <SelectItem key={i} value={item}>
                          {item}
                        </SelectItem>
                      ))}
                      <SelectItem value={null}>None</SelectItem>
                    </SelectContent>
                  </Select>
                </div>

                <div className="flex flex-col gap-y-1">
                  <Label>Placement Status</Label>
                  <Select
                    onValueChange={handleFilterChange("placed")}
                    defaultValue={filters.placed}
                  >
                    <SelectTrigger className="w-full">
                      <SelectValue placeholder="Status" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value={"Placed"}>Placed</SelectItem>
                      <SelectItem value={"Not Placed"}>Not Placed</SelectItem>
                      <SelectItem value={null}>None</SelectItem>
                    </SelectContent>
                  </Select>
                </div>

                <div className="flex flex-col gap-y-1">
                  <Label>Dream Placement</Label>
                  <Select
                    onValueChange={handleFilterChange("dreamPlacement")}
                    defaultValue={filters.dreamPlacement}
                  >
                    <SelectTrigger className="w-full">
                      <SelectValue placeholder="Dream Placement" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value={1}>Yes</SelectItem>
                      <SelectItem value={0}>No</SelectItem>
                      <SelectItem value={null}>None</SelectItem>
                    </SelectContent>
                  </Select>
                </div>

                <div className="flex flex-col gap-y-1">
                  <Label>Filter by GPA</Label>
                  <Select
                    onValueChange={handleFilterChange("gpa")}
                    defaultValue={filters.gpa}
                  >
                    <SelectTrigger className="w-full">
                      <SelectValue placeholder={filters.gpa} />
                    </SelectTrigger>
                    <SelectContent>
                      {gpas.map((gpa, i) => (
                        <SelectItem key={i} value={gpa}>
                          <div className="flex items-center">
                            <ChevronRight size={16} className="-mr-1" />
                            <p>= {gpa}</p>
                          </div>
                        </SelectItem>
                      ))}
                      <SelectItem value={null}>None</SelectItem>
                    </SelectContent>
                  </Select>
                </div>

                <div className="flex flex-col gap-y-1">
                  <Label>Live KT</Label>
                  <Select
                    onValueChange={handleFilterChange("liveKT")}
                    defaultValue={filters.liveKT}
                  >
                    <SelectTrigger className="w-full">
                      <SelectValue placeholder={filters.liveKT} />
                    </SelectTrigger>
                    <SelectContent>
                      {backlog.map((kt, i) => (
                        <SelectItem key={i} value={kt}>
                          <div className="flex items-center">
                            {kt >= 4 && i === 4 ? (
                              <ChevronRight size={16} className="-mr-1" />
                            ) : null}
                            <p>{kt >= 4 ? `>= ${kt}` : kt}</p>
                          </div>
                        </SelectItem>
                      ))}
                      <SelectItem value={null}>None</SelectItem>
                    </SelectContent>
                  </Select>
                </div>

                <div className="flex flex-col gap-y-1">
                  <Label>Total KT</Label>
                  <Select
                    onValueChange={handleFilterChange("totalKT")}
                    defaultValue={filters.totalKT}
                  >
                    <SelectTrigger className="w-full">
                      <SelectValue placeholder={filters.totalKT} />
                    </SelectTrigger>
                    <SelectContent>
                      {backlog.map((kt, i) => (
                        <SelectItem key={i} value={kt}>
                          <div className="flex items-center">
                            {kt >= 4 && i === 4 ? (
                              <ChevronRight size={16} className="-mr-1" />
                            ) : null}
                            <p>{kt >= 4 ? `>= ${kt}` : kt}</p>
                          </div>
                        </SelectItem>
                      ))}
                      <SelectItem value={null}>None</SelectItem>
                    </SelectContent>
                  </Select>
                </div>

                <div className="flex flex-col gap-y-2">
                  <Label>Show only incomplete profiles</Label>
                  <Switch
                    checked={filters.isProfileCompleted}
                    onCheckedChange={handleFilterChange("isProfileCompleted")}
                  />
                </div>
              </div>
              <Button
                variant="primary"
                className="rounded-3xl mt-4 text-sm"
                onClick={() => {
                  handleApplyFilters();

                  setIsSheetOpen(false);
                }}
              >
                Apply Filter
              </Button>
            </SheetContent>
          </Sheet>
          { filters.branch && filters.year &&
            <CreateAnnouncement
            year={filters.year} 
            branch={filters.branch} 
            placed={filters.placed} 
            dreamPlacement={filters.dreamPlacement} 
            cgpa={filters.gpa}
            liveKT={filters.liveKT}
            totalKT={filters.totalKT}
            studentPage="true"
          >
          <Button variant="primary" className="rounded-3xl shadow-md">
            + Create Announcement
          </Button>
        </CreateAnnouncement>
}
        </div>
      </div>

      <div className="mt-2">
        {studentLoading ? (
          <CompanyLoading />
        ) : (
          students &&
          students.result && (
            <DataTable
              hasClick={true}
              onClickRoute={(row) => navigate(row.id)}
              hasPagination={true}
              pagination={pagination}
              setPagination={setPagination}
              columns={StudentColumns}
              data={students}
            />
          )
        )}
      </div>
    </div>
  );
};
export default StudentList;
