import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../../headersService";


export const createAdditionalFields = createAsyncThunk('createAdditionalFields', async (body, { rejectWithValue }) => {
    try {
        const { data } = await axiosInstance.post(`${process.env.REACT_APP_BACKEND_URL}/api/job/additional-fields`, body);
        return data;
        
    } catch (error) {
        console.log(error)
        return rejectWithValue(error)
    }
})

export const additionalFieldSlice = createSlice({
    name: 'Additional Field',
    initialState: {
        loading: false,
        successMsg: null,
        error: null,
        additionalFields: []
    },
    reducers: {
        addAdditionalField: (state, action) => {
            state.additionalFields = [...state.additionalFields, action.payload]
        },
        resetAdditionalFields: (state) => {
            state.additionalFields = []
        },
        resetSuccessMsg: (state) => {
            state.successMsg = null
        },
        resetError: (state) => {
            state.error = null
        },
    },
    extraReducers: (builder) => {
        builder.addCase(createAdditionalFields.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(createAdditionalFields.fulfilled, (state, action) => {
            state.loading = false;
            state.successMsg = action.payload
            console.log(state.successMsg)
        })
        builder.addCase(createAdditionalFields.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload
        })
    }
})

export const { addAdditionalField, resetAdditionalFields, resetSuccessMsg, resetError } = additionalFieldSlice.actions
export default additionalFieldSlice.reducer