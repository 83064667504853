import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../ui/dialog";
import { Button } from "../ui/button";
import { Label } from "../ui/label";
import { Textarea } from "../ui/textarea";
import { Input } from "../ui/input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../ui/select";
import { createPublicAnnouncement, resetAnnouncementSuccessMsg } from "../../redux/features/public-announcements/createPublicAnnouncementSlice";
import CommonSelect from "../reusable/dropdown/CommonSelect";
import { toast } from "sonner";
import { Loader } from "lucide-react";


const CreateAnnouncement = ({ children, year, branch, placed, dreamPlacement, cgpa, liveKT, totalKT,studentPage }) => {
  const [open, setOpen] = useState(false); 
  const [loading,setLoading] = useState(false);
  const [announcementInfo, setAnnouncementInfo] = useState({
    title: "",
    subtitle: "",
    branch: "",
    graduationYear:"",
    placed: "",
    dreamPlacement: "",
    gpa:"",
    liveKT: "",
    totalKT: "",
  });

  const dispatch = useDispatch();
  const { branch: branchData } = useSelector((state) => state.collegeBranchReducer);
  const currentYear = new Date().getFullYear();
  const years = [currentYear, currentYear - 1, currentYear - 2, currentYear - 3];

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setAnnouncementInfo({ ...announcementInfo, [name]: value });
  };

  // Handle branch and year selection
  const handleSelectChange = (name, value) => {
    setAnnouncementInfo({ ...announcementInfo, [name]: value });
  };

  // Handle form submission
  const handleSubmit = async () => {
    setLoading(true);
    try {
      await dispatch(createPublicAnnouncement(announcementInfo)).unwrap();
      toast.success("Announcement created successfully!");
      setOpen(false); 
      dispatch(resetAnnouncementSuccessMsg())
    } catch (error) {
      toast.error("Failed to create announcement");
    } finally {
      setLoading(false); 
    }
  };
  

  useEffect(() => {
    setAnnouncementInfo({
      title: "",
      subtitle: "",
      branch: branch || "",
      graduationYear: year || "",
      placed: placed || "",
      dreamPlacement: dreamPlacement || "",
      gpa: cgpa || "",
      liveKT: liveKT || "",
      totalKT: totalKT || "",
    });
  }, [year, branch, placed, dreamPlacement, cgpa, liveKT, totalKT]);

  return (
    <div>
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogTrigger asChild>
          {children}
        </DialogTrigger>
        <DialogContent>
          <DialogHeader>
            <DialogTitle className="text-xl">Create Announcement</DialogTitle>
            <DialogDescription className="mt-3 flex flex-col space-y-3">
              <div className="flex flex-col">
                <Label htmlFor="title" className="text-black mb-2 text-base">
                  Announcement Title
                </Label>
                <Input
                  name="title"
                  value={announcementInfo.title}
                  onChange={handleChange}
                  placeholder="Announcement Title"
                />
              </div>

              <div className="flex flex-col gap-y-2">
                <Label htmlFor="subtitle" className="text-black mb-2 text-base">
                  Add Announcement Text
                </Label>
                <Textarea
                  name="subtitle"
                  value={announcementInfo.subtitle}
                  onChange={handleChange}
                  rows={5}
                  placeholder="Enter your text..."
                />
              </div>
             {!studentPage && (
              <>
              <div className="flex flex-col">
                <Label htmlFor="branch" className="text-black mb-2 text-base">
                  Branch
                </Label>
                <CommonSelect className={"w-full"} hideNone={true} handleSelectChange={(value) => handleSelectChange("branch", value)} items={branchData.map((branch) => ({ label: branch, value: branch }))} selectPlaceholder="Select Branch" />
              </div>
              
              <div>
                <Label htmlFor="graduationYear" className="text-black mb-2 text-base">
                  Graduation Year
                </Label>
                <CommonSelect className={"w-full"} hideNone={true} handleSelectChange={(value) => handleSelectChange("graduationYear", value)} items={years.map((year) => ({ label: year.toString(), value: year.toString() }))} selectPlaceholder="Select Year" />
              </div>
           </>
              )}
            </DialogDescription>
          </DialogHeader>

          <DialogFooter>
            <Button
              variant="primary"
              onClick={handleSubmit}
              className="rounded-3xl shadow-md"
              type="button"
              disabled={loading}
            >
           {loading ? (
            <>
           <Loader className="animate-spin" /> Creating Announcement
           </>
              ) : (
            "Create Announcement"
          )}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CreateAnnouncement;
