import { FormField, FormItem, FormLabel, FormControl, FormMessage } from "../../ui/form"
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from "../../ui/select"
const SelectFormField = ({ name, mandatory = true, control, className, labelClassName = "text-secondaryTextColor", label, placeholder, items }) => {
    return (
        <FormField
            control={control}
            name={name}
            render={({ field }) => (
                <FormItem className={className}>
                    <FormLabel className="w-[50%]">{label} {mandatory !== false ? <span className="text-red-500">*</span> : ""}</FormLabel>
                    <Select onValueChange={field.onChange} value={field.value}>
                        <FormControl>
                            <SelectTrigger>
                                <SelectValue placeholder={placeholder} />
                            </SelectTrigger>
                        </FormControl>
                        <SelectContent className="font-manrope">
                            {
                                items.map((item, i) => (
                                    <SelectItem key={i} value={item.value}>{item.label}</SelectItem>
                                ))
                            }

                        </SelectContent>
                    </Select>
                    <FormMessage />
                </FormItem>
            )}
        />)
}
export default SelectFormField