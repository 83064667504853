import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Context } from "../../context/Context";
import { useDispatch, useSelector } from "react-redux";
import DriveHeader from "./DriveHeader";
import DriveTable from "./DriveTable/DriveTable";
import { getDriveDropdown } from "../../redux/features/getDriveDropdownSlice";
import { Box, CircularProgress } from "@mui/material";
import { getAllDriveDetails } from "../../redux/features/getAllDriveDetailsSlice";
import CompanyLoading from "../Companies/CompanyLoading";
import { DriveColumns } from "./DriveTable/DriveColumns";
import { useLocation } from "react-router-dom";
import { DataTable } from "../reusable/table/DataTable";

const DriveList = () => {
  const location = useLocation();
  const { nav_status } = location.state || {};

  const { search, setSearch, toggleOn } = useContext(Context);
  const { user } = useSelector((state) => state.loginReducer);
  const { openMenu } = useSelector((state) => state.openMenu);
  const { loading, allDriveDetails } = useSelector(
    (state) => state.allDriveDetails
  );
  const { loading: postDriveLoading, success } = useSelector(
    (state) => state.postDrives
  );

  const [status, setStatus] = useState("Ongoing");
  const { type } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (nav_status) {
      setStatus("Completed");
      dispatch(
        getAllDriveDetails({ campusType: "On Campus", status: "Completed" })
      );
    } else {
      dispatch(getDriveDropdown());
      dispatch(
        getAllDriveDetails({
          campusType: type === "on-campus-drive" ? "On Campus" : "Off Campus",
          status: status,
        })
      );
    }
  }, [type, status, success]);

  return (
    <div className="mt-3">
      <DriveHeader status={status} setStatus={setStatus} />
      <div className="mt-2">
        {loading || postDriveLoading ? (
          <CompanyLoading />
        ) : (
          allDriveDetails && (
            <DataTable
              hasClick={true}
              hasPagination={false}
              onClickRoute={(row) => navigate(`/drive/drive-details/${row.id}`)}
              columns={DriveColumns}
              data={allDriveDetails}
            />
          )
        )}
      </div>
    </div>
  );
};
export default DriveList;
