import { Switch } from "../../ui/switch"
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "../../ui/form"

const SwitchFormField = ({ control, name, label, className }) => {
    return (
        <FormField
            control={control}
            name={name}
            render={({ field }) => (
                <FormItem className={className}>
                    <FormLabel>
                        {label}
                    </FormLabel>
                    <FormControl>
                        <Switch
                            className="mt-0"
                            checked={field.value}
                            onCheckedChange={field.onChange}
                        />
                    </FormControl>
                    <FormMessage />

                </FormItem >
            )}
        />
    )
}
export default SwitchFormField