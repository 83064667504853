import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva } from "class-variance-authority";

import { cn } from "../../lib/utils";

const buttonVariants = cva(
  "inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
  {
    variants: {
      variant: {
        default: "bg-primary text-primary-foreground hover:bg-primary/90",
        destructive:
          "bg-destructive text-destructive-foreground hover:bg-destructive/90",
        outline:
          "border border-input bg-background hover:bg-accent hover:text-accent-foreground",
        secondary:
          "bg-cardBgColor/20 text-[#DA1F24] hover:bg-cardBgColor/40 border rounded-2xl",
        ghost: "hover:bg-accent hover:text-accent-foreground",
        link: "text-primary underline-offset-4 hover:underline",
        primary:
          "bg-primaryButtonColor hover:bg-primaryButtonColor/70 rounded-lg text-white font-semibold",
        outlined:
          "bg-white shadow-md hover:bg-white/90 border rounded-2xl text-primaryTextColor font-semibold",
        disabled:
          "bg-primary text-primary-foreground hover:bg-primary/90 opacity-40 cursor-not-allowed",
      },
      size: {
        default: "h-9 px-4 py-2",
        sm: "h-6 shadow-md rounded-md px-3",
        md: "h-7 w-[160px] rounded-3xl py-4 px-6",
        lg: "h-11 rounded-md px-8",
        icon: "h-10 w-10",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
);

const Button = React.forwardRef(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    );
  }
);
Button.displayName = "Button";

export { Button, buttonVariants };
