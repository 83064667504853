import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import axiosInstance from '../../headersService'


export const fetchAllStudents = createAsyncThunk('fetchAllStudents', async (obj, { rejectWithValue }) => {
    try {
        const { data } = await axiosInstance.get(`${process.env.REACT_APP_BACKEND_URL}/api/student`, {
            params: {
                type: obj.type,
                branch: obj.branch,
                placed: obj.placed,
                dreamPlacement: obj.dreamPlacement,
                name: obj.name,
                year: obj.year,
                gpa: obj.gpa,
                KTLive: obj.KTLive,
                KTtotal: obj.KTtotal,
                limit: obj.limit,
                offset: obj.offset,
                isProfileCompleted : (obj.isProfileCompleted === undefined || obj.isProfileCompleted === null || obj.isProfileCompleted === false) ? null : false
            }
        })
        return data
    } catch (error) {
        console.log(error)
        return rejectWithValue(error)
    }
})

export const studentSlice = createSlice({
    name: 'student',
    initialState: {
        loading: false,
        students: {},
        error: null
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAllStudents.pending, (state, action) => {
            state.loading = true;
            state.students = {}
        })
        builder.addCase(fetchAllStudents.fulfilled, (state, action) => {
            state.loading = false;
            state.students = action.payload
        })
        builder.addCase(fetchAllStudents.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload
        })
    }
})

export default studentSlice.reducer