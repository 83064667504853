import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import axiosInstance from "../../headersService";

export const getParicularDriveDetail = createAsyncThunk(
  "getParicularDriveDetail",
  async (obj, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/drive/drive-detail/${obj.id}`
      );
      return data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);

export const withdrawDrive = createAsyncThunk(
  "withdrawDrive",
  async (driveId, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/drive/withdraw-invite/${driveId}`
      );
      // Assuming the API returns a success message
      return data.message; // return only the message
    } catch (error) {
      console.log(error);
      return rejectWithValue(
        error.response.data.message || "An error occurred"
      );
    }
  }
);

export const particularDriveSlice = createSlice({
  name: "Drive Detail",
  initialState: {
    loading: false,
    particularDriveDetail: {},
    error: null,
    driveAnnouncement: false,
    withdrawalSuccess:false,
  },
  reducers: {
    updateDriveAnnouncement: (state, action) => {
      state.driveAnnouncement = action.payload;
    },
    resetWithdrawalSuccess: (state) => {
      state.withdrawalSuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getParicularDriveDetail.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getParicularDriveDetail.fulfilled, (state, action) => {
      state.loading = false;
      state.particularDriveDetail = action.payload;
    });
    builder.addCase(getParicularDriveDetail.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(withdrawDrive.pending, (state) => {
      state.loading = true;
      state.withdrawalSuccess = false;
    });
    builder.addCase(withdrawDrive.fulfilled, (state, action) => {
      state.loading = false;
      state.withdrawalSuccess = true; 
      
    });
    builder.addCase(withdrawDrive.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.withdrawalSuccess = false;
    });
  },
});

export const { updateDriveAnnouncement,resetWithdrawalSuccess } = particularDriveSlice.actions;
export default particularDriveSlice.reducer;
