import { configureStore } from "@reduxjs/toolkit";
import companySlice from "../features/companySlice";
import companyDropdownSlice from "../features/companyDropdownSlice";
import studentSlice from "../features/studentSlice";
import studentDetailSlice from "../features/studentDetailSlice";
import menuSlice from "../features/menuSlice";
import companyDetailSlice from "../features/companyDetailSlice";
import companyIndustrySlice from "../features/companyIndustrySlice";
import connectionAddSlice from "../features/connectionAddSlice";
import snackbarSlice from "../features/snackbarSlice";
import connectionGetSlice from "../features/connectionGetSlice";
import allInvitationSlice from "../features/allInvitationSlice";
import removeInvitaionSlice from "../features/removeInvitaionSlice";
import getConnectionStatusCount from "../features/connectionCountSlice";
import allYearBranchSlice from "../features/allYearBranchSlice";
import BranchAndYearTrackSlice from "../features/BranchAndYearTrackSlice";
import studentPlacedSlice from "../features/getStudentRegisterdAndPlacedData";
import companyVisitedCount from "../features/companiesVisitedCountSlice";
import studentPlacedGender from "../features/getStudentPlacedViaGender";
import studentsPlacedCountSlice from "../features/totalPlacedStudentsCountSlice";
import recentlyConnectedSlice from "../features/recentlyConnectedCompaniesSlice";
import loginSlice from "../features/loginSlice";
import placedCompanyStudentsSlice from "../features/selectedCompanyStudentsCountSlice";
import ctcOfferedSlice from "../features/ctcOfferedSlice";
import getDriveDropdownSlice from "../features/getDriveDropdownSlice";
import getAllDriveDetailsSlice from "../features/getAllDriveDetailsSlice";
import getParticularDriveDetailSlice from "../features/getParticularDriveDetailSlice";
import scheduleDriveCompaniesListSlice from "../features/ScheduleDriveCompaniesListSlice";
import fetchCorporateNetworkSlice from "../features/fetchCorporateNetworkSlice";
import addDriveSlice from "../features/addDriveSlice";
import getRoundDetailsSlice from "../features/getRoundDetailsSlice";
import myTeamDetailsSlice from "../features/getMyTeamDetails";
import getRoundApplicantsSlice from "../features/getRoundApplicantsSlice";
import firebaseTokenSlice from "../features/firebaseAuthtoken";
import studentPlacedDetails from "../features/getStudentPlacementDetailsSlice";
import studentDocsSlice from "../features/getStudentDocsSlice";
import managePermissionsSlice from "../features/teamManagePermissions";
import companyLocationDropdownSlice from "../features/companyLocationSlice";
import companyBranchAndLocationSlice from "../features/companyBranchAndLocationSlice";
import getAllTeamMembers from "../features/getAllTeamMembers";
import addMemberSlice from "../features/addMemberSlice";
import placementGraphSlice from "../features/placementGraphSlice";
import collegeSlice from "../features/getAllCollegeSlice";
import collaboratorSlice from "../features/getCollaborators";
import studentSheetSlice from "../features/addStudentsSheet";
import studentViewSlice from "../features/studentViewSlice";
import percentageWisePlaced from "../features/percentageWisePlaced";
import newRecurringSlice from "../features/newRecurringSlice";
import getReportsSlice from "../features/getReportsSlice";
import reportsFilterSlice from "../features/reportsYearFilterSlice";
import connectionUpdateSlice from "../features/connectionUpdateSlice";
import jobFormSlice from "../features/jobFormSlice";
import jobFormDetailSlice from "../features/jobFormDetailSlice";
import driveFromJobResponseSlice from "../features/driveFromJobResSlice";
import registeredDriveStudentsSlice from "../features/getRegisteredDriveStudentsSlice";
import updateStudentApplicationStatusSlice from "../features/updateStudentApplicationStatusSlice";
import startDriveSlice from "../features/startDriveFromCollegeSlice";
import moveToNextRoundSlice from "../features/moveToNextRoundSlice";
import driveJobRolesSlice from "../features/driveJobRolesSlice";
import jobRolesSlice from "../features/getCompanyJobRoles";
import getBranchWiseRegAndPlaced from "../features/getBranchwisePlaced";
import endDriveSlice from "../features/endDriveSlice";
import notificationSlice from "../features/notificationsSlice";
import updateNotificationSlice from "../features/updateNotificationReadSlice";
import collegeBranchSlice from "../features/getCollegeBranch";
import eventsSlice from "../features/eventsReducerSlice";
import getDriveAnnouncementSlice from "../features/drive-announcements/getDriveAnnouncementSlice";
import createAnnouncementSlice from "../features/drive-announcements/createAnnouncementSlice";
import announcementDetailSlice from "../features/drive-announcements/getAnnouncementDetails";
import downloadStudentSheetSlice from "../features/students/downloadStudentSheet";
import myProfileSlice from "../features/tpo/myProfileSlice";
import updateTpoProfileSlice from "../features/tpo/updateTpoProfileSlice";
import updatePrivacyPolicySlice from "../features/updatePrivacyPolicySlice";
import createPublicAnnouncementSlice from "../features/public-announcements/createPublicAnnouncementSlice";
import getPublicAnnouncementSlice from "../features/public-announcements/getPublicAnnouncementSlice";
import getPublicAnnouncementDetails  from "../features/public-announcements/getPublicAnnouncementDetails";
import additionalFieldSlice from "../features/job-form/additionalFieldSlice";

export const store = configureStore({
    reducer: {
        loginReducer: loginSlice,
        getCompanies: companySlice,
        getCompanyBranches: companyDropdownSlice,
        getCompanyLocationDropdown: companyLocationDropdownSlice,
        companyBranchLocationSet: companyBranchAndLocationSlice,
        getAllStudents: studentSlice,
        getStudentDetail: studentDetailSlice,
        openMenu: menuSlice,
        getCompanyDetail: companyDetailSlice,
        getCompanyIndustry: companyIndustrySlice,
        addConnection: connectionAddSlice,
        snackbarReducer: snackbarSlice,
        getAllConnections: connectionGetSlice,
        getAllInvitation: allInvitationSlice,
        getConnectionCount: getConnectionStatusCount,
        deleteInvitation: removeInvitaionSlice,
        allBranchYear: allYearBranchSlice,
        studentBranchYear: BranchAndYearTrackSlice,
        studentPlaced: studentPlacedSlice,
        companyVisited: companyVisitedCount,
        studentPlacedByGender: studentPlacedGender,
        studentsPlacedCount: studentsPlacedCountSlice,
        recentlyConnected: recentlyConnectedSlice,
        selectedCompanyStudentCount: placedCompanyStudentsSlice,
        ctcOffered: ctcOfferedSlice,
        allDriveDetails: getAllDriveDetailsSlice,
        getDriveDropdown: getDriveDropdownSlice,
        getParticularDriveDetails: getParticularDriveDetailSlice,
        scheduleDriveCompanies: scheduleDriveCompaniesListSlice,
        corporateNetwork: fetchCorporateNetworkSlice,
        postDrives: addDriveSlice,
        fetchRoundDetails: getRoundDetailsSlice,
        myTeamDetails: myTeamDetailsSlice,
        roundApplicants: getRoundApplicantsSlice,
        authToken: firebaseTokenSlice,
        studentPlacedDetails: studentPlacedDetails,
        studentDocuments: studentDocsSlice,
        managePermissions: managePermissionsSlice,
        getAllMembers: getAllTeamMembers,
        addMember: addMemberSlice,
        placementGraph: placementGraphSlice,
        allColleges: collegeSlice,
        collaboratorsReducer: collaboratorSlice,
        studentsSheetReducer: studentSheetSlice,
        studentViewReducer: studentViewSlice,
        percentageWisePlaced: percentageWisePlaced,
        newRecurring: newRecurringSlice,
        getTopReports: getReportsSlice,
        reportsFilter: reportsFilterSlice,
        connectionUpdateReducer: connectionUpdateSlice,
        jobFormReducer: jobFormSlice,
        jobFormDetailReducer: jobFormDetailSlice,
        driveFromJobResReducer: driveFromJobResponseSlice,
        registeredDriveStudentReducer: registeredDriveStudentsSlice,
        updateStudentApplicationStatusReducer: updateStudentApplicationStatusSlice,
        startDriveReducer: startDriveSlice,
        nextRoundReducer: moveToNextRoundSlice,
        driveJobRolesReducer: driveJobRolesSlice,
        jobRolesReducer: jobRolesSlice,
        branchWiseRegAndPlaced: getBranchWiseRegAndPlaced,
        updatePrivacyPolicyReducer: updatePrivacyPolicySlice,

        endDriveReducer: endDriveSlice,
        collegeBranchReducer: collegeBranchSlice,
        notificationReducer: notificationSlice,
        updateNotificationReducer: updateNotificationSlice,
        eventsReducer: eventsSlice,
        getDriveAnnouncementReducer: getDriveAnnouncementSlice,
        createDriveAnnouncementReducer: createAnnouncementSlice,
        announcementDetailReducer: announcementDetailSlice,
        downloadStudentSheetReducer: downloadStudentSheetSlice,
        myProfileReducer: myProfileSlice,
        updateTpoProfileReducer: updateTpoProfileSlice,

        additionalFieldReducer : additionalFieldSlice,

        //public announcemnt
        createPublicAnnouncementReducer: createPublicAnnouncementSlice,
        getPublicAnnouncementReducer: getPublicAnnouncementSlice,
        publicAnnouncementDetailReducer: getPublicAnnouncementDetails,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
})