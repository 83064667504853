import { useDispatch, useSelector } from "react-redux"
import { useEffect } from "react"
import { fetchCompanies,resetCompanies,incrementOffset } from "../redux/features/companySlice"
import { fetchCompanyDropdown } from "../redux/features/companyDropdownSlice"
import { getAllConnections } from "../redux/features/connectionGetSlice"
import { fetchCompanyDropdownLocation } from "../redux/features/companyLocationSlice"
import CompaniesList from "../components/Companies/CompaniesList"
import { resetConnectionAddError, resetConnectionAddSuccess } from "../redux/features/connectionAddSlice"
import { toast } from "sonner"
import { resetConnectionDeleteError, resetConnectionDeleteSuccess } from "../redux/features/removeInvitaionSlice"
import { useLocation } from 'react-router-dom';

const CompaniesDirectory = () => {
  const { success: addSuccess, error: addConnectionError } = useSelector((state) => state.addConnection)
  //fetching success of a deleting a connection request to fetch the list of companies again without refreshing the page
  const { success: deleteSuccess, error: deleteConnectionError } = useSelector((state) => state.deleteInvitation)
  const { success: updateSuccess } = useSelector((state) => state.connectionUpdateReducer)
  //fetching the user for institutionId
  const { user } = useSelector((state) => state.loginReducer)
  const dispatch = useDispatch()
  //fetching the token from the localstorage

  const location = useLocation();
  const {invite_sent,invite_accept } = location.state || {};

  const { selectedBranch, selectedLocation } = useSelector(
    (state) => state.companyBranchLocationSet
  );

  useEffect(() => {
    //fetching all companies and dropdown as soon as the site loads
    if(invite_sent){
      dispatch(fetchCompanies({ location: "", branch: "" ,status:"Approached" }))
    }
    else if(invite_accept){
      dispatch(fetchCompanies({ location: "", branch: "" , status:"Accepted" }))
    }
    else if (addSuccess || deleteSuccess) {
      toast.success(addSuccess ? "Invitation sent successfully" : "Invitation withdrawn successfully");
      dispatch(addSuccess ? resetConnectionAddSuccess() : resetConnectionDeleteSuccess());
    }
    else if (addConnectionError || deleteConnectionError) {
      toast.error(addConnectionError || deleteConnectionError);
      dispatch(addConnectionError ? resetConnectionAddError() : resetConnectionDeleteError());
    }
    else{
    dispatch(resetCompanies());
    dispatch(fetchCompanies({ location: selectedLocation || "", branch:selectedBranch || "",limit:10 }))
    dispatch(incrementOffset());
    dispatch(fetchCompanyDropdown({ type: 'hire-type' }))
    dispatch(fetchCompanyDropdownLocation())
    dispatch(getAllConnections())

  }
  }, [addSuccess, deleteSuccess, updateSuccess, addConnectionError, deleteConnectionError,invite_sent,invite_accept])

  return (
    <div className="">
      <CompaniesList />
    </div>
  );
};
export default CompaniesDirectory;
