import React, { useState } from 'react'
import { Button } from '../components/ui/button'
import CreateAnnouncement from '../components/Announcement/CreateAnnouncement'
import { AnnouncementColumns } from '../components/Announcement/AnnouncementColumns'
import { DataTable } from '../components/reusable/table/DataTable'
import { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { getCollegeBranches } from '../redux/features/getCollegeBranch'
import { getPublicAnnouncements } from '../redux/features/public-announcements/getPublicAnnouncementSlice'
import CompanyLoading from '../components/Companies/CompanyLoading'
import { toast } from 'sonner'
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from '../components/ui/dialog'
import AnnouncementDetailLoading from '../components/drives/AnnouncementDetailLoading'
import {getPublicAnnouncementDetails } from '../redux/features/public-announcements/getPublicAnnouncementDetails'

const AnnouncementPage = () => {
  const dispatch = useDispatch();
  const { branch: branchData } = useSelector((state) => state.collegeBranchReducer);
  const { announcements, loading } = useSelector(state => state.getPublicAnnouncementReducer);
  const { loading:creatingLoading,successMsg, error} = useSelector((state) => state.createPublicAnnouncementReducer);
  const { announcementDetail,loading:announcementDetailLoading } = useSelector((state) => state.publicAnnouncementDetailReducer);

  useEffect(() => {
    dispatch(getCollegeBranches());
    dispatch(getPublicAnnouncements())
}, [dispatch,successMsg,error])

const [open, setOpen] = useState(false)

const handleAnnouncementDetails = (row) => {
    setOpen(true)
    dispatch(getPublicAnnouncementDetails(row.id))
}
  return (
    <div className="flex items-left gap-x-4 justify-between flex-col">
    <div className="flex items-center gap-x-4">
        <h1 className="font-bold text-2xl">Announcement Database</h1>

        {      <CreateAnnouncement>
                <Button variant="primary" className="rounded-3xl shadow-md">
                    + Create Announcement
                </Button>
                </CreateAnnouncement>

        }
    </div>
    <div>
    {
       loading || creatingLoading ? <CompanyLoading /> :announcements && <DataTable hasClick={true} hasPagination={false} data={announcements} columns={AnnouncementColumns} onClickRoute={handleAnnouncementDetails} />
    }
     <Dialog open={open} onOpenChange={setOpen}>
        <DialogContent>
            <DialogHeader>
                {
                  announcementDetailLoading ? <AnnouncementDetailLoading /> :
                    <>
                       <DialogTitle className="text-xl">{announcementDetail?.[0].title}</DialogTitle>
                            <DialogDescription className="mt-3 flex flex-col space-y-3">
                                {announcementDetail?.[0].subtitle}
                            </DialogDescription>
                    </>
                }
            </DialogHeader>
        </DialogContent>
    </Dialog>
    </div>
    </div>
    
  )
}

export default AnnouncementPage
