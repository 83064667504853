import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { getParicularDriveDetail } from "../../redux/features/getParticularDriveDetailSlice"
import { useDispatch, useSelector } from "react-redux"
import { useEffect } from "react"

import { getRoundDetails } from "../../redux/features/getRoundDetailsSlice"
import { resetStartDriveError, resetStartDriveSuccess, startDrive } from "../../redux/features/startDriveFromCollegeSlice"
import DriveDetailsHeader from "./DriveDetailsHeader"
import RoundStatus from "./RoundStatus"
import DriveDetailLoading from "./DriveDetailLoading"
import DriveRoles from "./DriveRoles"
import { getDriveJobRoles } from "../../redux/features/driveJobRolesSlice"
import { Button } from "../ui/button"
import { toast } from "sonner"
import DriveDescription from "./DriveDescription"
import KeyValuedetails from "../students/KeyValuedetails"

const DriveDetails = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { particularDriveDetail: driveDetail, loading,driveAnnouncement } = useSelector((state) => state.getParticularDriveDetails)
  const { success: startDriveSuccess, loading: startDriveLoading, message: startDriveSuccessMsg, error: startDriveError } = useSelector((state) => state.startDriveReducer)
  const { roundDetails } = useSelector((state) => state.fetchRoundDetails)
  const { companyData } = driveDetail
  const { jobRoles } = useSelector((state) => state.driveJobRolesReducer)
  const { success: nextRoundSuccess, loading: nextRoundLoading, message: nextRoundSuccessMsg, error: nextRoundError } = useSelector((state) => state.nextRoundReducer)

  useEffect(() => {
    dispatch(getParicularDriveDetail({ id: id }))
    dispatch(getRoundDetails({ id: id }))
    dispatch(getDriveJobRoles(id))
  }, [id, startDriveSuccess, nextRoundSuccess])

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-US', options);
  };



  const handleStartDrive = () => {

    dispatch(startDrive(id))
  }

  useEffect(() => {
    if (startDriveSuccess) {
      toast.success(startDriveSuccessMsg)
      dispatch(resetStartDriveSuccess())
    }
    if (startDriveError) {
      toast.success(startDriveError)
      dispatch(resetStartDriveError())
    }
  }, [startDriveSuccess, startDriveError])


  return (
    <div className="flex flex-col mt-3 px-4">
      {
        loading || startDriveLoading ? <DriveDetailLoading /> :
          <>
            <DriveDetailsHeader />
            <div className="flex justify-between mt-4">

              <RoundStatus roundDetails={roundDetails} controlledBy={driveDetail?.controlled_by} />
              {
                driveDetail?.controlled_by === 'COL' &&
                <div>
                  {
                    !driveDetail?.isDriveStarted &&
                    <Button variant="primary" onClick={handleStartDrive}>Start Drive</Button>
                  }
                </div>
              }
            </div>
            {
              driveDetail?.controlled_by === 'COM' && <DriveDescription
                description={driveDetail?.description}
                eligibilityCriteria={driveDetail?.eligibility_criteria}
                ctcMin={driveDetail?.ctc_min}
                ctcMax={driveDetail?.ctc_max}
                pocName={driveDetail?.companyData?.pocName}
                pocEmail={driveDetail?.companyData?.pocEmail}
              />
            }
            <DriveRoles controlledBy={driveDetail?.controlled_by} jobRoles={jobRoles} jobTitles={driveDetail.job_titles} />
            <div className="mt-4">
      <h1 className="font-semibold text-2xl">Additional Information</h1>
      <div className="mt-2"></div>
            <div className="rounded-lg border-2 shadow-lg px-6 py-4">
          {driveDetail?.additionalFields?.length > 0 ? (
            <div className="space-y-2">
              {driveDetail?.additionalFields.map((field) => (
                <KeyValuedetails
                  key={field.id}
                  title={field.label}
                  value={
                    field.field_type === "boolean"
                      ? field.value === "true" ? "Yes" : "No"
                      : field.value || "-"
                  }
                />
              ))}
            </div>
          ) : (
            <p className="text-gray-500">No additional fields available.</p>
          )}
        </div>
        </div>
          </>
      }
    </div>
  )
}
export default DriveDetails