
export const AnnouncementColumns = [
    {
        accessorKey: "title",
        header: "Announcement Title",
    },
    {
        accessorKey: "branch",
        header: "Branch",
    },
    {
        accessorKey: "year",
        header: "Graduation Year",
    },
    {
        accessorKey: "created_at",
        header: "Date of Announcement",
        cell : ({row}) => {
            return <p>{row.original.created_at}</p>
        }
    },
]