import { createSlice } from "@reduxjs/toolkit";

export const studentBranchYearSlice = createSlice({
    name: 'branchYear',
    initialState: {
        branch: null,
        year: null,
        placed: null,
        dreamPlacement: null,
        gpa: null,
        liveKT: null,
        totalKT: null,
        isProfileCompleted: null
    },
    reducers: {
        setFilter: (state, action) => {
            const { key, value } = action.payload;
            state[key] = value;
        },

    }
})
export const { setFilter, resetFilters } = studentBranchYearSlice.actions;
export default studentBranchYearSlice.reducer